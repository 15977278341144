<template>
  <moe-page title="权限管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="roleSearch">
      <el-form-item label="角色名称">
        <el-input
          v-model.trim="roleParams.name"
          placeholder="请输入角色名称"
          maxlength="100"
          clearable
        ></el-input>
      </el-form-item>
    </moe-inquire>

    <!-- 角色信息 -->
    <moe-table ref="roleTable" url="/role/pageList" :params="roleParams">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="$router.push('/role/add')"
          >角色添加
        </el-button>
      </template>
      <el-table-column prop="name" label="角色名称" min-width="150" />
      <el-table-column prop="info" label="功能权限" min-width="500" />
      <el-table-column prop="remarks" label="备注" min-width="300" />
      <el-table-column label="操作" width="210" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-edit"
            @click="$router.push(`/role/amend?id=${row.id}`)"
          >
            修改
          </el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="roleRemove(row.id)"
            >删除</el-button
          >
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
// import changeColor from '@/utils/theme.js'
export default {
  name: 'RoleList',
  data() {
    //角色筛选信息
    let roleParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      name: '', //角色名称
    };

    return {
      roleParams, //角色筛选信息
    };
  },
  methods: {
    /**
     * 修改角色状态
     **/
    roleStatusChange(row, state) {
      // 调用修改角色状态API
      this.$api.role_API
        .roleAmend({
          userId: row.userId,
          state,
        })
        .then((data) => {
          if (data.code == 200) {
            this.$message.success(state === 'Y' ? '启用成功' : '禁用成功');

            //刷新角色表格
            this.$refs.roleTable.loadingData();
          } else {
            this.$message.error(data.message);
          }
        });
    },

    /**
     * 角色删除
     **/
    roleRemove(id) {
      this.$moe_layer.confirm('是否要删除该角色信息 ?', () => {
        // 调用删除角色API
        this.$moe_api.ROLE_API.roleDelete({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');

            //刷新角色表格
            this.$refs.roleTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },

    /**
     * 角色搜索
     **/
    roleSearch(isSearch) {
      if (!isSearch) {
        this.roleParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          name: '', //角色名称
        };
      }

      //刷新角色表格
      this.$refs.roleTable.searchData();
    },
  },
};
</script>